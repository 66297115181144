.greenText {
    color: var(--green_theme);
}

.blackText {
    color: black;
}

.cardCell {
    display: flex;
    justify-content: space-between;
    background-color: #F5F5F5;
  }

  .imageListContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }

  .imageListContainer img {
    align-self: center;
  }