
/* New Style */
/*Large dont give pixels, Medium max-width: 800px, Small max-width: 500px*/

/* Large */
 .CondicoStepsSubSection {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: '55%';
  }
  
  /* Medium screens */
  /*@media all and (max-width: 800px) {
	.CondicoStepsSubSection {
	  justify-content: space-around;
	}
  }*/
  
  /* Small screens */
  @media all and (max-width: 500px) {
	.CondicoStepsSubSection {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		width: '55%';
	}
  }

  /* End new Styles */

/* Basic */

	@-ms-viewport {
		width: device-width;
	}

	body {
		-ms-overflow-style: scrollbar;
	}

	@media screen and (max-width: 480px) {

		html, body {
			min-width: 320px;
		}

	}

/* Type */

	body, input, select, textarea {
		cursor:pointer;
		color:#333;
		font-family: "Roboto, helvetica";
		font-size: 12pt;
		font-weight: normal;
		line-height: 1.75;
	}
	
	@media screen and (max-width: 1680px) {

		body, input, select, textarea {
			font-size: 13pt;
		}

	}

	@media screen and (max-width: 1280px) {

		body, input, select, textarea {
			font-size: 12pt;
		}

	}

	@media screen and (max-width: 360px) {

		body, input, select, textarea {
			font-size: 11pt;
		}

	}

	a {
		-moz-transition: color 0.2s ease, border-bottom-color 0.2s ease;
		-webkit-transition: color 0.2s ease, border-bottom-color 0.2s ease;
		-ms-transition: color 0.2s ease, border-bottom-color 0.2s ease;
		transition: color 0.2s ease, border-bottom-color 0.2s ease;
		border-bottom: dotted 1px rgba(255, 255, 255, 0.35);
		color: inherit;
		text-decoration: none;
	}

		a:hover {
			border-bottom-color: transparent;
			color: #ffffff;
		}

	strong, b {
		color: #ffffff;
		font-weight: bold;
	}

	em, i {
		font-style: italic;
	}

	p {
		margin: 0 0 2em 0;
	}

	h1, h2, h3, h4, h5, h6 {
		color: #666;
		font-weight: bold;
		line-height: 1.5;
		margin: 0 0 0.5em 0;
	}

	h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
		color: inherit;
		text-decoration: none;
	}

	h1 {
		font-size: 2.75em;
	}

	h1.major {
		margin: 0 0 1.3em 0;
		position: relative;
		padding-bottom: 0.35em;
	}

	h1.major:after {
		background-image: -moz-linear-gradient(to right, #5e42a6, #b74e91);
		background-image: -webkit-linear-gradient(to right, #5e42a6, #b74e91);
		background-image: -ms-linear-gradient(to right, #5e42a6, #b74e91);
		background-image: linear-gradient(to right, #5e42a6, #b74e91);
		-moz-transition: max-width 0.2s ease;
		-webkit-transition: max-width 0.2s ease;
		-ms-transition: max-width 0.2s ease;
		transition: max-width 0.2s ease;
		border-radius: 0.2em;
		bottom: 0;
		content: '';
		height: 0.05em;
		position: absolute;
		right: 0;
		width: 100%;
	}

	h2 {
		font-size: 1.75em;
	}

	h3 {
		font-size: 1.1em;
	}

	h4 {
		font-size: 1em;
	}

	h5 {
		font-size: 0.8em;
	}

	h6 {
		font-size: 0.6em;
	}

	@media screen and (max-width: 736px) {

		h1 {
			font-size: 2em;
		}

		h2 {
			font-size: 1.25em;
		}

		h3 {
			font-size: 1em;
		}

		h4 {
			font-size: 0.8em;
		}

		h5 {
			font-size: 0.6em;
		}

		h6 {
			font-size: 0.6em;
		}

	}

	sub {
		font-size: 0.8em;
		position: relative;
		top: 0.5em;
	}

	sup {
		font-size: 0.8em;
		position: relative;
		top: -0.5em;
	}

	blockquote {
		border-left: solid 4px rgba(255, 255, 255, 0.15);
		font-style: italic;
		margin: 0 0 2em 0;
		padding: 0.5em 0 0.5em 2em;
	}

	code {
		background: rgba(255, 255, 255, 0.05);
		border-radius: 0.25em;
		border: solid 1px rgba(255, 255, 255, 0.15);
		font-family: "Courier New", monospace;
		font-size: 0.9em;
		margin: 0 0.25em;
		padding: 0.25em 0.65em;
	}

	pre {
		-webkit-overflow-scrolling: touch;
		font-family: "Courier New", monospace;
		font-size: 0.9em;
		margin: 0 0 2em 0;
	}

		pre code {
			display: block;
			line-height: 1.75em;
			padding: 1em 1.5em;
			overflow-x: auto;
		}

	hr {
		border: 0;
		border-bottom: solid 1px rgba(255, 255, 255, 0.15);
		margin: 2em 0;
	}

		hr.major {
			margin: 3em 0;
		}

	.align-left {
		text-align: left;
	}

	.align-center {
		text-align: center;
	}

	.align-right {
		text-align: right;
	}

/* Button */

	.whiteButton {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
		-moz-transition: border-color 0.2s ease;
		-webkit-transition: border-color 0.2s ease;
		-ms-transition: border-color 0.2s ease;
		transition: border-color 0.2s ease;
		background-color:#fff;
		xborder: solid 1px ;
		xborder-color: #fff; ;
		border-radius: 3em;
		color: #8ACF2E; ;
		cursor: pointer;
		display: inline-block;
		font-size: 0.9em;
		font-weight: bold;
		font-family: "AvenirNext";
		xheight: calc(4.75em + 2px);
		xletter-spacing: 0.1em;
		line-height: 2em;
		outline: 0;
		padding: .5em 2em;
		position: relative;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		white-space: nowrap;
	}

	.button {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
		-moz-transition: border-color 0.2s ease;
		-webkit-transition: border-color 0.2s ease;
		-ms-transition: border-color 0.2s ease;
		transition: border-color 0.2s ease;
		background-color:#8ACF2E;
		xborder: solid 1px ;
		xborder-color: #7BD728; ;
		border-radius: 3em;
		color: #FFF;
		cursor: pointer;
		display: inline-block;
		font-size: 0.9em;
		font-weight: bold;
		font-family: "AvenirNext";
		xheight: calc(4.75em + 2px);
		xletter-spacing: 0.1em;
		line-height: 2em;
		outline: 0;
		padding: .5em 2em;
		position: relative;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		white-space: nowrap;
	}
	
	.button:hover, .button:active, .button:active:focus, .button:visited, .button:focus {
		outline: 0;
	}

/* Features */

	.features {
		display: -moz-flex;
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		-moz-flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-radius: 0.25em;
		border: solid 1px rgba(255, 255, 255, 0.15);
		background: rgba(255, 255, 255, 0.05);
		margin: 0 0 2em 0;
	}

	.features section {
		padding: 3.5em 3em 1em 7em ;
		width: 50%;
		border-top: solid 1px rgba(255, 255, 255, 0.15);
		position: relative;
	}

	.features section:nth-child(-n + 2) {
		border-top-width: 0;
	}

	.features section:nth-child(2n) {
		border-left: solid 1px rgba(255, 255, 255, 0.15);
	}

	.features section .icon {
		-moz-transition: opacity 0.5s ease, -moz-transform 0.5s ease;
		-webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
		-ms-transition: opacity 0.5s ease, -ms-transform 0.5s ease;
		transition: opacity 0.5s ease, transform 0.5s ease;
		-moz-transition-delay: 1s;
		-webkit-transition-delay: 1s;
		-ms-transition-delay: 1s;
		transition-delay: 1s;
		-moz-transform: scale(1);
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
		position: absolute;
		left: 3em;
		top: 3em;
		opacity: 1;
	}

	.features section:nth-child(1) .icon {
		-moz-transition-delay: 0.15s;
		-webkit-transition-delay: 0.15s;
		-ms-transition-delay: 0.15s;
		transition-delay: 0.15s;
	}

	.features section:nth-child(2) .icon {
		-moz-transition-delay: 0.3s;
		-webkit-transition-delay: 0.3s;
		-ms-transition-delay: 0.3s;
		transition-delay: 0.3s;
	}

	.features section:nth-child(3) .icon {
		-moz-transition-delay: 0.45s;
		-webkit-transition-delay: 0.45s;
		-ms-transition-delay: 0.45s;
		transition-delay: 0.45s;
	}

	.features section:nth-child(4) .icon {
		-moz-transition-delay: 0.6s;
		-webkit-transition-delay: 0.6s;
		-ms-transition-delay: 0.6s;
		transition-delay: 0.6s;
	}

	.features section:nth-child(5) .icon {
		-moz-transition-delay: 0.75s;
		-webkit-transition-delay: 0.75s;
		-ms-transition-delay: 0.75s;
		transition-delay: 0.75s;
	}

	.features section:nth-child(6) .icon {
		-moz-transition-delay: 0.9s;
		-webkit-transition-delay: 0.9s;
		-ms-transition-delay: 0.9s;
		transition-delay: 0.9s;
	}

	.features section:nth-child(7) .icon {
		-moz-transition-delay: 1.05s;
		-webkit-transition-delay: 1.05s;
		-ms-transition-delay: 1.05s;
		transition-delay: 1.05s;
	}

	.features section:nth-child(8) .icon {
		-moz-transition-delay: 1.2s;
		-webkit-transition-delay: 1.2s;
		-ms-transition-delay: 1.2s;
		transition-delay: 1.2s;
	}

	.features section:nth-child(9) .icon {
		-moz-transition-delay: 1.35s;
		-webkit-transition-delay: 1.35s;
		-ms-transition-delay: 1.35s;
		transition-delay: 1.35s;
	}

	.features section:nth-child(10) .icon {
		-moz-transition-delay: 1.5s;
		-webkit-transition-delay: 1.5s;
		-ms-transition-delay: 1.5s;
		transition-delay: 1.5s;
	}

	.features section:nth-child(11) .icon {
		-moz-transition-delay: 1.65s;
		-webkit-transition-delay: 1.65s;
		-ms-transition-delay: 1.65s;
		transition-delay: 1.65s;
	}

	.features section:nth-child(12) .icon {
		-moz-transition-delay: 1.8s;
		-webkit-transition-delay: 1.8s;
		-ms-transition-delay: 1.8s;
		transition-delay: 1.8s;
	}

	.features section:nth-child(13) .icon {
		-moz-transition-delay: 1.95s;
		-webkit-transition-delay: 1.95s;
		-ms-transition-delay: 1.95s;
		transition-delay: 1.95s;
	}

	.features section:nth-child(14) .icon {
		-moz-transition-delay: 2.1s;
		-webkit-transition-delay: 2.1s;
		-ms-transition-delay: 2.1s;
		transition-delay: 2.1s;
	}

	.features section:nth-child(15) .icon {
		-moz-transition-delay: 2.25s;
		-webkit-transition-delay: 2.25s;
		-ms-transition-delay: 2.25s;
		transition-delay: 2.25s;
	}

	.features section:nth-child(16) .icon {
		-moz-transition-delay: 2.4s;
		-webkit-transition-delay: 2.4s;
		-ms-transition-delay: 2.4s;
		transition-delay: 2.4s;
	}

	.features section:nth-child(17) .icon {
		-moz-transition-delay: 2.55s;
		-webkit-transition-delay: 2.55s;
		-ms-transition-delay: 2.55s;
		transition-delay: 2.55s;
	}

	.features section:nth-child(18) .icon {
		-moz-transition-delay: 2.7s;
		-webkit-transition-delay: 2.7s;
		-ms-transition-delay: 2.7s;
		transition-delay: 2.7s;
	}

	.features section:nth-child(19) .icon {
		-moz-transition-delay: 2.85s;
		-webkit-transition-delay: 2.85s;
		-ms-transition-delay: 2.85s;
		transition-delay: 2.85s;
	}

	.features section:nth-child(20) .icon {
		-moz-transition-delay: 3.0s;
		-webkit-transition-delay: 3.0s;
		-ms-transition-delay: 3.0s;
		transition-delay: 3.0s;
	}

	.features.inactive section .icon {
		-moz-transform: scale(0.5);
		-webkit-transform: scale(0.5);
		-ms-transform: scale(0.5);
		transform: scale(0.5);
		opacity: 0;
	}

	@media screen and (max-width: 980px) {

		.features {
			display: block;
		}

			.features section {
				border-top-width: 1px ;
				border-left-width: 0 ;
				width: 100%;
			}

				.features section:first-child {
					border-top-width: 0 ;
				}

	}

	@media screen and (max-width: 736px) {

		.features section {
			padding: 2.5em 1.5em 0.1em 5.5em ;
		}

			.features section .icon {
				left: 1.5em;
				top: 2em;
			}

	}

	@media screen and (max-width: 480px) {

		.features section {
			padding: 2em 1.5em 0.1em 1.5em ;
		}

			.features section .icon {
				left: 0;
				position: relative;
				top: 0;
			}

	}

/* Form */

	.CondicoForm {
		margin: 0 0 2em 0;
	}

	.CondicoForm .field {
		margin: 0 0 1em 0;
	}

	.CondicoForm .field.half {
		width: 50%;
		float: left;
		padding: 0 0 0 0.75em;
	}

	.CondicoForm .field.half.first {
		padding: 0 0.75em 0 0;
	}

	.CondicoForm > .actions {
		margin: 2em 0 0 0 ;
	}

	@media screen and (max-width: 736px) {
		.CondicoForm .field.half {
			width: 100%;
			float: none;
			padding: 0;
		}

		.CondicoForm .field.half.first {
			padding: 0;
		}
	}

	.CondicoLabel {
		color: #ffffff;
		font-weight: bold;
		line-height: 1.5;
		margin: 0 0 0.7em 0;
		display: block;
		font-size: 1.1em;
	}

	.CondicoInput[type="text"],
	.CondicoInput[type="password"],
	.CondicoInput[type="email"],
	.CondicoInput[type="tel"] {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
		background: rgba(255, 255, 255, 0.05);
		border-radius: 0.25em;
		border: none;
		border: solid 1px rgba(255, 255, 255, 0.15);
		color: #ffffff;
		height: 2.5em;
		display: block;
		outline: 0;
		padding: 0 1em;
		text-decoration: none;
		width: 100%;
	}

	.CondicoText[type="text"],
	textarea {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
		background: rgba(255, 255, 255, 0.05);
		border-radius: 0.25em;
		border: none;
		border: solid 1px rgba(255, 255, 255, 0.15);
		color: #ffffff;
		height: 4.0em;
		display: block;
		outline: 0;
		padding: 0 1em;
		text-decoration: none;
		width: 100%;
	}

/* Section/Article */

	section.special, article.special {
		text-align: center;
	}

	header p {
		color: rgba(255, 255, 255, 0.35);
		position: relative;
		margin: 0 0 1.5em 0;
	}

	header h2 + p {
		font-size: 1.25em;
		margin-top: -1em;
		line-height: 1.5em;
	}

	header h3 + p {
		font-size: 1.1em;
		margin-top: -0.8em;
		line-height: 1.5em;
	}

	header h4 + p,
	header h5 + p,
	header h6 + p {
		font-size: 0.9em;
		margin-top: -0.6em;
		line-height: 1.5em;
	}

/* Spotlights */

	.spotlights > section {
		display: -moz-flex;
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		-moz-flex-direction: row;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		min-height: 22.5em;
	}

	body.is-ie .spotlights > section {
		min-height: 0;
	}

	.spotlights > section > .image {
		background-position: center center;
		background-size: cover;
		border-radius: 0;
		display: block;
		position: relative;
		width: 25em;

	}
	  
	.spotlights > section > .image img {
		border-radius: 0;
		display: block;
		vertical-align:bottom;
	}

	.spotlights > section > .image:before {
		-moz-transition: opacity 1s ease;
		-webkit-transition: opacity 1s ease;
		-ms-transition: opacity 1s ease;
		transition: opacity 1s ease;
		background: #3F740A;
		background-image:'url(../../img/image_rollover_3.png)';
		background-size:cover;
		background-position:center;
		content: '';
		display: block;
		height: 100%;
		left: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		width: 110%;
	}

	.spotlights > section > .content {
		padding: 4em 5em 2em 5em ;
		display: -moz-flex;
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		-moz-flex-direction: column;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-moz-justify-content: center;
		-webkit-justify-content: center;
		-ms-justify-content: center;
		justify-content: center;
		width: 70em;
		-ms-flex: 1;
	}

	.spotlights > section > .content > .inner {
		-moz-transform: translateX(0) translateY(0);
		-webkit-transform: translateX(0) translateY(0);
		-ms-transform: translateX(0) translateY(0);
		transform: translateX(0) translateY(0);
		-moz-transition: opacity 1s ease, -moz-transform 1s ease;
		-webkit-transition: opacity 1s ease, -webkit-transform 1s ease;
		-ms-transition: opacity 1s ease, -ms-transform 1s ease;
		transition: opacity 1s ease, transform 1s ease;
		opacity: 1;
	}

	.spotlights > section:nth-child(2) {
		background-color: rgba(0, 0, 0, 0.05);
	}

	.spotlights > section:nth-child(3) {
		background-color: rgba(0, 0, 0, 0.1);
	}

	.spotlights > section.inactive > .image:before, body.is-loading .spotlights > section > .image:before {
		opacity: 1;
	}

	.spotlights > section.inactive > .content > .inner, body.is-loading .spotlights > section > .content > .inner {
		-moz-transform: translateX(-1em);
		-webkit-transform: translateX(-1em);
		-ms-transform: translateX(-1em);
		transform: translateX(-1em);
		opacity: 0;
	}

	@media screen and (max-width: 1680px) {

		.spotlights > section > .content {
			xpadding: 4em 4em 2em 4em ;
			padding: 4em 6em 2em 4em ;

		}

	}

	@media screen and (max-width: 980px) {

		.spotlights > section {
			display: block;
		}

			.spotlights > section > .image {
				width: 100%;
				height: 100%;
				vertical-align:bottom;
				xpadding-left: 10em;
			}

			.spotlights > section > .content {
				width: 100%;
			}

			.spotlights > section.inactive > .content > .inner, body.is-loading .spotlights > section > .content > .inner {
				-moz-transform: translateY(1em);
				-webkit-transform: translateY(1em);
				-ms-transform: translateY(1em);
				transform: translateY(1em);
			}
	}

	@media screen and (max-width: 736px) {

		.spotlights > section > .image {
			xheight: 50vh;
			min-height: 15em;

		}

		.spotlights > section > .content {
			padding: 3em 2em 1em 2em ;
		}

	}

/* Table */

	.table-wrapper {
		-webkit-overflow-scrolling: touch;
		overflow-x: auto;
	}

	table {
		margin: 0 0 2em 0;
		width: 100%;
	}

	table tbody tr {
		border-left: 0;
		border-right: 0;
	}

	table td {
		padding: 0.75em 0.75em;
	}

	table th {
		color: #ffffff;
		font-size: 1em;
		font-weight: bold;
		padding: 0 0.75em 0.75em 0.75em;
		text-align: left;
	}

	table thead {
		border-bottom: solid 2px rgba(255, 255, 255, 0.15);
	}

	table tfoot {
		border-top: solid 2px rgba(255, 255, 255, 0.15);
	}

	table.alt {
		border-collapse: separate;
	}

	table.alt tbody tr td {
		border: solid 1px rgba(255, 255, 255, 0.15);
		border-left-width: 0;
		border-top-width: 0;
	}

		table.alt tbody tr td:first-child {
			border-left-width: 1px;
		}

	table.alt tbody tr:first-child td {
		border-top-width: 1px;
	}

	table.alt thead {
		border-bottom: 0;
	}

	table.alt tfoot {
		border-top: 0;
	}

/* Wrapper */

	.wrapper {
		position: relative;
	}

	.wrapper > .inner {
		padding: 0em 5em 3em 5em ;
		max-width: 100%;
		width: 75em;
	}

	@media screen and (max-width: 1680px) {

		.wrapper > .inner {
			padding: 0em 4em 2em 4em ;
			width:100%;
		}

	}

	@media screen and (max-width: 1280px) {

		.wrapper > .inner {
			width: 100%;
		}

	}

	@media screen and (max-width: 736px) {

		.wrapper > .inner {
			padding: 3em 2em 1em 2em ;
		}

	}

	.wrapper.alt {
		background-color: #000;
	}

	.wrapper.style1 {
		background-color: #000;
		background-image:'url(../../img/footer_background.jpg)';
		background-size:cover;
		xbox-shadow:#000;
		box-shadow:inset 0 20px 20px -20px;
	}

	.wrapper.style1-alt {
		background-color: #000;
	}

	.wrapper.style2 {
		background-color: #FFF;
		xbox-shadow:#000;
		xbox-shadow:inset 0px 20px 20px -20px;
	}

	.wrapper.style2-alt {
		background-color: #FFF;
	}

	.wrapper.style3 {
		background-color: #ffffff;
		background-image:'url(../../img/faq_bg.jpg)';
		background-size:cover;
	}

	.wrapper.style3-alt {
		background-color: #FFF;
	}

	.wrapper.fullscreen {
		display: -moz-flex;
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		-moz-flex-direction: column;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-moz-justify-content: center;
		-webkit-justify-content: center;
		-ms-justify-content: center;
		justify-content: center;
		min-height: 100vh;
	}

		body.is-ie .wrapper.fullscreen {
			height: 100vh;
		}

		@media screen and (max-width: 1280px) {

			.wrapper.fullscreen {
				min-height: calc(100vh - 2.5em);
			}

				body.is-ie .wrapper.fullscreen {
					height: calc(100vh - 2.5em);
				}

		}

		@media screen and (max-width: 736px) {

			.wrapper.fullscreen {
				padding: 2em 0;
				min-height: 0;
			}

				body.is-ie .wrapper.fullscreen {
					height: auto;
				}

		}

	.wrapper.fade-up > .inner {
		-moz-transform: translateY(0);
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
		-moz-transition: opacity 1s ease, -moz-transform 1s ease;
		-webkit-transition: opacity 1s ease, -webkit-transform 1s ease;
		-ms-transition: opacity 1s ease, -ms-transform 1s ease;
		transition: opacity 1s ease, transform 1s ease;
		opacity: 1.0;
	}

	.wrapper.fade-up.inactive > .inner, body.is-loading .wrapper.fade-up > .inner {
		opacity: 0;
		-moz-transform: translateY(1em);
		-webkit-transform: translateY(1em);
		-ms-transform: translateY(1em);
		transform: translateY(1em);
	}

	.wrapper.fade-down > .inner {
		-moz-transform: translateY(0);
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
		-moz-transition: opacity 1s ease, -moz-transform 1s ease;
		-webkit-transition: opacity 1s ease, -webkit-transform 1s ease;
		-ms-transition: opacity 1s ease, -ms-transform 1s ease;
		transition: opacity 1s ease, transform 1s ease;
		opacity: 1.0;
	}

	.wrapper.fade-down.inactive > .inner, body.is-loading .wrapper.fade-down > .inner {
		opacity: 0;
		-moz-transform: translateY(-1em);
		-webkit-transform: translateY(-1em);
		-ms-transform: translateY(-1em);
		transform: translateY(-1em);
	}

	.wrapper.fade > .inner {
		-moz-transition: opacity 1s ease;
		-webkit-transition: opacity 1s ease;
		-ms-transition: opacity 1s ease;
		transition: opacity 1s ease;
		opacity: 1.0;
	}

	.wrapper.fade.inactive > .inner, body.is-loading .wrapper.fade > .inner {
		opacity: 0;
	}



/* Intro */

	#intro {
		zbackground-image:none;
		background-attachment: fixed;
		background-image:'url(../../img/home_background4.jpg)';
		background-position: top right;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		box-shadow:inset -20px -25px 15px -30px #000;
	}

	#intro p {
		font-family:inherit;
		font-size: 1em;
		font-weight: bold;
		color:#444;
	}

	@media screen and (max-width: 980px) {
		#intro p br {
			display: none;
			background-size: 100% 100%;
		}

		.condicoGifImage {
			width: 400px
		}
	}

	@media screen and (max-width: 736px) {
		#intro p {
			font-size: 1em;
			background-size: 100% 100%;
		}

		.condicoGifImage {
			width: 600px
		}
	}

	@media screen and (max-width: 1280px) {

		#intro {
			background-attachment: scroll;
			background-size: 100% 100%;
		}

		.condicoGifImage {
			width: 300px
		}
	}