br {
    line-height: 200%;
}

.imageBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.stateBox {
    display: flex;
    justify-content: center;
    align-items: center;
}

.summaryBox {
    display: flex;
    flex-direction: row;
}