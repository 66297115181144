.ButtonContainer {
    width: 50%;
    margin: auto;
}

.whiteButton {
    background-color:#fff;
    xborder-color: #fff;
    color: #8ACF2E;
}

.modal-content {
    background-color: var(--grey_theme); 
}

.modalbox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modalImage {
    align-self: center;
}