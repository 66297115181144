.FiltersBox {
    display: flex;
    justify-content: space-between;
}

.FilterTitles {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Switches {
    padding-left: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.DatePickers {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.filtersButton {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -moz-transition: border-color 0.2s ease;
    -webkit-transition: border-color 0.2s ease;
    -ms-transition: border-color 0.2s ease;
    transition: border-color 0.2s ease;
    background-color:#8ACF2E;
    xborder: solid 1px ;
    xborder-color: #7BD728; ;
    color: #FFF; ;
    cursor: pointer;
    display: inline-block;
    font-size: 0.9em;
    font-weight: bold;
    font-family: "AvenirNext";
    xheight: calc(4.75em + 2px);
    xletter-spacing: 0.1em;
    line-height: 2em;
    outline: 0;
    padding: .5em 2em;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    width: 100%;
}