@media all and (min-width: 480px) {
  
    .ForgotPassword {
      padding: 60px 0;
    }

    .ForgotPassword form {
      margin: 0 auto;
      max-width: 320px;
    }

    .ForgotPasswordLeadPages form {
      margin: 0 auto;
      max-width: 320px;
    }
    
  }

  .ForgotPasswordLeadPages {
    padding: 60px 0;
    background: linear-gradient(#1B1836,#1B1836);
    height: 2000px;
  }