@media all and (min-width: 480px) {
    .Signup {
      padding: 60px 0;
      background: linear-gradient(#808080,#fff);
    }
  
    .Signup form {
      margin: 0 auto;
      max-width: 320px;
    }

    .SignUpLeadPages form {
      margin: 0 auto;
      max-width: 320px;
    }

    .alert {
      margin-top: -60px;
    }

  }

  .SignUpLeadPages {
    padding: 60px 0;
    background: linear-gradient(#1B1836,#1B1836);
    height: 2000px;
  }