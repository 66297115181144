.contactUsJumbotron {
    margin: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    text-align: left;
  }

  .contactUsJumbotron .infoDivider {
    height:20vh;
  }

  .contactUsJumbotron .transactionDivider {
    height:15vh;
  }

  .contactUsJumbotron .messageDivider {
    height:65vh;
  }

  .contactInfo {
    display: flex;
    flex-direction: column;
  }

  .contactUsCustomText {
      font-size: 16px;
      font-family: "Roboto, helvetica";
      color: var(--darkGrey_theme);
  }

  .address, .messageRow {
    display: flex;
    flex-direction: row;
  }

  .messageRow {
    justify-content: space-between;
  }

  .ButtonContainer {
    width: 50%;
    margin: auto;
  }