.App {
  width: 100vw;
}

.navbar {
  margin-bottom: 0;
  background-color: #a9a9a9;
  min-height: 80px;
}

.navMenuIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.navMenuIcon img {
  align-self: center;
  padding-right: 3px;
}

.navMenuIcon p {
  align-self: center;
  font-weight: normal;
  padding: 0;
  margin: 0;
  font-family: "Roboto, helvetica";
  font-size: 16px;
}

.navbar-default .navbar-nav> .active> a, .navbar-default .navbar-nav > .active> a:focus, .navbar-default .navbar-nav> .active > a:hover {
  color: #669900;
  background-color: transparent;
}

.navbar-default .navbar-nav > li > a {
  color:black;
  border-width: 0px;
}

.navbar-default .navbar-brand {
  color:black;
  border-width: 0px;
  height: 80px;
  line-height: 80px;
}

.navbar-toggle {
  /* (80px - button height 34px) / 2 = 23px */
  margin-top: 23px;
  padding: 9px 10px !important;
}

.navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:hover {
  color:#777;
  border-width: 0px;
}

.navbar-default .navbar-brand:active, .navbar-default .navbar-brand:focus, .navbar-default .navbar-brand:hover {
  color:#777;
  border-width: 0px;
}

button:focus {outline:0;}


.FiltersBox {
    display: flex;
    justify-content: space-between;
}

.FilterTitles {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Switches {
    padding-left: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.DatePickers {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.filtersButton {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    transition: border-color 0.2s ease;
    background-color:#8ACF2E;
    xborder: solid 1px ;
    xborder-color: #7BD728; ;
    color: #FFF; ;
    cursor: pointer;
    display: inline-block;
    font-size: 0.9em;
    font-weight: bold;
    font-family: "AvenirNext";
    xheight: calc(4.75em + 2px);
    xletter-spacing: 0.1em;
    line-height: 2em;
    outline: 0;
    padding: .5em 2em;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    width: 100%;
}
.ButtonContainer {
    width: 50%;
    margin: auto;
}
.Summary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "Helvetica";
}

.ButtonContainer {
    width: 50%;
    margin: auto;
}
.ButtonContainer {
    width: 50%;
    margin: auto;
}

.whiteButton {
    background-color:#fff;
    xborder-color: #fff;
    color: #8ACF2E;
}

.modal-content {
    background-color: var(--grey_theme); 
}

.modalbox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modalImage {
    align-self: center;
}
.btn-default {
    box-shadow: none !important;
    outline: none !important;
}

.btn-default:hover, .btn-default:focus, .btn-default:active {
    box-shadow: none !important;
    outline: none !important;
}

.btn-default.active {
    box-shadow: none !important;
    outline: none !important;
}

/* New Style */
/*Large dont give pixels, Medium max-width: 800px, Small max-width: 500px*/

/* Large */
 .CondicoStepsSubSection {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: '55%';
  }
  
  /* Medium screens */
  /*@media all and (max-width: 800px) {
	.CondicoStepsSubSection {
	  justify-content: space-around;
	}
  }*/
  
  /* Small screens */
  @media all and (max-width: 500px) {
	.CondicoStepsSubSection {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		width: '55%';
	}
  }

  /* End new Styles */

/* Basic */

	@-ms-viewport {
		width: device-width;
	}

	body {
		-ms-overflow-style: scrollbar;
	}

	@media screen and (max-width: 480px) {

		html, body {
			min-width: 320px;
		}

	}

/* Type */

	body, input, select, textarea {
		cursor:pointer;
		color:#333;
		font-family: "Roboto, helvetica";
		font-size: 12pt;
		font-weight: normal;
		line-height: 1.75;
	}
	
	@media screen and (max-width: 1680px) {

		body, input, select, textarea {
			font-size: 13pt;
		}

	}

	@media screen and (max-width: 1280px) {

		body, input, select, textarea {
			font-size: 12pt;
		}

	}

	@media screen and (max-width: 360px) {

		body, input, select, textarea {
			font-size: 11pt;
		}

	}

	a {
		transition: color 0.2s ease, border-bottom-color 0.2s ease;
		border-bottom: dotted 1px rgba(255, 255, 255, 0.35);
		color: inherit;
		text-decoration: none;
	}

		a:hover {
			border-bottom-color: transparent;
			color: #ffffff;
		}

	strong, b {
		color: #ffffff;
		font-weight: bold;
	}

	em, i {
		font-style: italic;
	}

	p {
		margin: 0 0 2em 0;
	}

	h1, h2, h3, h4, h5, h6 {
		color: #666;
		font-weight: bold;
		line-height: 1.5;
		margin: 0 0 0.5em 0;
	}

	h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
		color: inherit;
		text-decoration: none;
	}

	h1 {
		font-size: 2.75em;
	}

	h1.major {
		margin: 0 0 1.3em 0;
		position: relative;
		padding-bottom: 0.35em;
	}

	h1.major:after {
		background-image: linear-gradient(to right, #5e42a6, #b74e91);
		transition: max-width 0.2s ease;
		border-radius: 0.2em;
		bottom: 0;
		content: '';
		height: 0.05em;
		position: absolute;
		right: 0;
		width: 100%;
	}

	h2 {
		font-size: 1.75em;
	}

	h3 {
		font-size: 1.1em;
	}

	h4 {
		font-size: 1em;
	}

	h5 {
		font-size: 0.8em;
	}

	h6 {
		font-size: 0.6em;
	}

	@media screen and (max-width: 736px) {

		h1 {
			font-size: 2em;
		}

		h2 {
			font-size: 1.25em;
		}

		h3 {
			font-size: 1em;
		}

		h4 {
			font-size: 0.8em;
		}

		h5 {
			font-size: 0.6em;
		}

		h6 {
			font-size: 0.6em;
		}

	}

	sub {
		font-size: 0.8em;
		position: relative;
		top: 0.5em;
	}

	sup {
		font-size: 0.8em;
		position: relative;
		top: -0.5em;
	}

	blockquote {
		border-left: solid 4px rgba(255, 255, 255, 0.15);
		font-style: italic;
		margin: 0 0 2em 0;
		padding: 0.5em 0 0.5em 2em;
	}

	code {
		background: rgba(255, 255, 255, 0.05);
		border-radius: 0.25em;
		border: solid 1px rgba(255, 255, 255, 0.15);
		font-family: "Courier New", monospace;
		font-size: 0.9em;
		margin: 0 0.25em;
		padding: 0.25em 0.65em;
	}

	pre {
		-webkit-overflow-scrolling: touch;
		font-family: "Courier New", monospace;
		font-size: 0.9em;
		margin: 0 0 2em 0;
	}

		pre code {
			display: block;
			line-height: 1.75em;
			padding: 1em 1.5em;
			overflow-x: auto;
		}

	hr {
		border: 0;
		border-bottom: solid 1px rgba(255, 255, 255, 0.15);
		margin: 2em 0;
	}

		hr.major {
			margin: 3em 0;
		}

	.align-left {
		text-align: left;
	}

	.align-center {
		text-align: center;
	}

	.align-right {
		text-align: right;
	}

/* Button */

	.whiteButton {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
		transition: border-color 0.2s ease;
		background-color:#fff;
		xborder: solid 1px ;
		xborder-color: #fff; ;
		border-radius: 3em;
		color: #8ACF2E; ;
		cursor: pointer;
		display: inline-block;
		font-size: 0.9em;
		font-weight: bold;
		font-family: "AvenirNext";
		xheight: calc(4.75em + 2px);
		xletter-spacing: 0.1em;
		line-height: 2em;
		outline: 0;
		padding: .5em 2em;
		position: relative;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		white-space: nowrap;
	}

	.button {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
		transition: border-color 0.2s ease;
		background-color:#8ACF2E;
		xborder: solid 1px ;
		xborder-color: #7BD728; ;
		border-radius: 3em;
		color: #FFF;
		cursor: pointer;
		display: inline-block;
		font-size: 0.9em;
		font-weight: bold;
		font-family: "AvenirNext";
		xheight: calc(4.75em + 2px);
		xletter-spacing: 0.1em;
		line-height: 2em;
		outline: 0;
		padding: .5em 2em;
		position: relative;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		white-space: nowrap;
	}
	
	.button:hover, .button:active, .button:active:focus, .button:visited, .button:focus {
		outline: 0;
	}

/* Features */

	.features {
		display: -moz-flex;
		display: -ms-flex;
		display: flex;
		flex-wrap: wrap;
		border-radius: 0.25em;
		border: solid 1px rgba(255, 255, 255, 0.15);
		background: rgba(255, 255, 255, 0.05);
		margin: 0 0 2em 0;
	}

	.features section {
		padding: 3.5em 3em 1em 7em ;
		width: 50%;
		border-top: solid 1px rgba(255, 255, 255, 0.15);
		position: relative;
	}

	.features section:nth-child(-n + 2) {
		border-top-width: 0;
	}

	.features section:nth-child(2n) {
		border-left: solid 1px rgba(255, 255, 255, 0.15);
	}

	.features section .icon {
		transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
		transition: opacity 0.5s ease, transform 0.5s ease;
		transition: opacity 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease;
		transition-delay: 1s;
		-webkit-transform: scale(1);
		transform: scale(1);
		position: absolute;
		left: 3em;
		top: 3em;
		opacity: 1;
	}

	.features section:nth-child(1) .icon {
		transition-delay: 0.15s;
	}

	.features section:nth-child(2) .icon {
		transition-delay: 0.3s;
	}

	.features section:nth-child(3) .icon {
		transition-delay: 0.45s;
	}

	.features section:nth-child(4) .icon {
		transition-delay: 0.6s;
	}

	.features section:nth-child(5) .icon {
		transition-delay: 0.75s;
	}

	.features section:nth-child(6) .icon {
		transition-delay: 0.9s;
	}

	.features section:nth-child(7) .icon {
		transition-delay: 1.05s;
	}

	.features section:nth-child(8) .icon {
		transition-delay: 1.2s;
	}

	.features section:nth-child(9) .icon {
		transition-delay: 1.35s;
	}

	.features section:nth-child(10) .icon {
		transition-delay: 1.5s;
	}

	.features section:nth-child(11) .icon {
		transition-delay: 1.65s;
	}

	.features section:nth-child(12) .icon {
		transition-delay: 1.8s;
	}

	.features section:nth-child(13) .icon {
		transition-delay: 1.95s;
	}

	.features section:nth-child(14) .icon {
		transition-delay: 2.1s;
	}

	.features section:nth-child(15) .icon {
		transition-delay: 2.25s;
	}

	.features section:nth-child(16) .icon {
		transition-delay: 2.4s;
	}

	.features section:nth-child(17) .icon {
		transition-delay: 2.55s;
	}

	.features section:nth-child(18) .icon {
		transition-delay: 2.7s;
	}

	.features section:nth-child(19) .icon {
		transition-delay: 2.85s;
	}

	.features section:nth-child(20) .icon {
		transition-delay: 3.0s;
	}

	.features.inactive section .icon {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
		opacity: 0;
	}

	@media screen and (max-width: 980px) {

		.features {
			display: block;
		}

			.features section {
				border-top-width: 1px ;
				border-left-width: 0 ;
				width: 100%;
			}

				.features section:first-child {
					border-top-width: 0 ;
				}

	}

	@media screen and (max-width: 736px) {

		.features section {
			padding: 2.5em 1.5em 0.1em 5.5em ;
		}

			.features section .icon {
				left: 1.5em;
				top: 2em;
			}

	}

	@media screen and (max-width: 480px) {

		.features section {
			padding: 2em 1.5em 0.1em 1.5em ;
		}

			.features section .icon {
				left: 0;
				position: relative;
				top: 0;
			}

	}

/* Form */

	.CondicoForm {
		margin: 0 0 2em 0;
	}

	.CondicoForm .field {
		margin: 0 0 1em 0;
	}

	.CondicoForm .field.half {
		width: 50%;
		float: left;
		padding: 0 0 0 0.75em;
	}

	.CondicoForm .field.half.first {
		padding: 0 0.75em 0 0;
	}

	.CondicoForm > .actions {
		margin: 2em 0 0 0 ;
	}

	@media screen and (max-width: 736px) {
		.CondicoForm .field.half {
			width: 100%;
			float: none;
			padding: 0;
		}

		.CondicoForm .field.half.first {
			padding: 0;
		}
	}

	.CondicoLabel {
		color: #ffffff;
		font-weight: bold;
		line-height: 1.5;
		margin: 0 0 0.7em 0;
		display: block;
		font-size: 1.1em;
	}

	.CondicoInput[type="text"],
	.CondicoInput[type="password"],
	.CondicoInput[type="email"],
	.CondicoInput[type="tel"] {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
		background: rgba(255, 255, 255, 0.05);
		border-radius: 0.25em;
		border: none;
		border: solid 1px rgba(255, 255, 255, 0.15);
		color: #ffffff;
		height: 2.5em;
		display: block;
		outline: 0;
		padding: 0 1em;
		text-decoration: none;
		width: 100%;
	}

	.CondicoText[type="text"],
	textarea {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
		background: rgba(255, 255, 255, 0.05);
		border-radius: 0.25em;
		border: none;
		border: solid 1px rgba(255, 255, 255, 0.15);
		color: #ffffff;
		height: 4.0em;
		display: block;
		outline: 0;
		padding: 0 1em;
		text-decoration: none;
		width: 100%;
	}

/* Section/Article */

	section.special, article.special {
		text-align: center;
	}

	header p {
		color: rgba(255, 255, 255, 0.35);
		position: relative;
		margin: 0 0 1.5em 0;
	}

	header h2 + p {
		font-size: 1.25em;
		margin-top: -1em;
		line-height: 1.5em;
	}

	header h3 + p {
		font-size: 1.1em;
		margin-top: -0.8em;
		line-height: 1.5em;
	}

	header h4 + p,
	header h5 + p,
	header h6 + p {
		font-size: 0.9em;
		margin-top: -0.6em;
		line-height: 1.5em;
	}

/* Spotlights */

	.spotlights > section {
		display: -moz-flex;
		display: -ms-flex;
		display: flex;
		-moz-flex-direction: row;
		flex-direction: row;
		min-height: 22.5em;
	}

	body.is-ie .spotlights > section {
		min-height: 0;
	}

	.spotlights > section > .image {
		background-position: center center;
		background-size: cover;
		border-radius: 0;
		display: block;
		position: relative;
		width: 25em;

	}
	  
	.spotlights > section > .image img {
		border-radius: 0;
		display: block;
		vertical-align:bottom;
	}

	.spotlights > section > .image:before {
		transition: opacity 1s ease;
		background: #3F740A;
		background-image:'url(../../img/image_rollover_3.png)';
		background-size:cover;
		background-position:center;
		content: '';
		display: block;
		height: 100%;
		left: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		width: 110%;
	}

	.spotlights > section > .content {
		padding: 4em 5em 2em 5em ;
		display: -moz-flex;
		display: -ms-flex;
		display: flex;
		-moz-flex-direction: column;
		flex-direction: column;
		-moz-justify-content: center;
		-ms-justify-content: center;
		justify-content: center;
		width: 70em;
		-ms-flex: 1;
	}

	.spotlights > section > .content > .inner {
		-webkit-transform: translateX(0) translateY(0);
		transform: translateX(0) translateY(0);
		transition: opacity 1s ease, -webkit-transform 1s ease;
		transition: opacity 1s ease, transform 1s ease;
		transition: opacity 1s ease, transform 1s ease, -webkit-transform 1s ease;
		opacity: 1;
	}

	.spotlights > section:nth-child(2) {
		background-color: rgba(0, 0, 0, 0.05);
	}

	.spotlights > section:nth-child(3) {
		background-color: rgba(0, 0, 0, 0.1);
	}

	.spotlights > section.inactive > .image:before, body.is-loading .spotlights > section > .image:before {
		opacity: 1;
	}

	.spotlights > section.inactive > .content > .inner, body.is-loading .spotlights > section > .content > .inner {
		-webkit-transform: translateX(-1em);
		transform: translateX(-1em);
		opacity: 0;
	}

	@media screen and (max-width: 1680px) {

		.spotlights > section > .content {
			xpadding: 4em 4em 2em 4em ;
			padding: 4em 6em 2em 4em ;

		}

	}

	@media screen and (max-width: 980px) {

		.spotlights > section {
			display: block;
		}

			.spotlights > section > .image {
				width: 100%;
				height: 100%;
				vertical-align:bottom;
				xpadding-left: 10em;
			}

			.spotlights > section > .content {
				width: 100%;
			}

			.spotlights > section.inactive > .content > .inner, body.is-loading .spotlights > section > .content > .inner {
				-webkit-transform: translateY(1em);
				transform: translateY(1em);
			}
	}

	@media screen and (max-width: 736px) {

		.spotlights > section > .image {
			xheight: 50vh;
			min-height: 15em;

		}

		.spotlights > section > .content {
			padding: 3em 2em 1em 2em ;
		}

	}

/* Table */

	.table-wrapper {
		-webkit-overflow-scrolling: touch;
		overflow-x: auto;
	}

	table {
		margin: 0 0 2em 0;
		width: 100%;
	}

	table tbody tr {
		border-left: 0;
		border-right: 0;
	}

	table td {
		padding: 0.75em 0.75em;
	}

	table th {
		color: #ffffff;
		font-size: 1em;
		font-weight: bold;
		padding: 0 0.75em 0.75em 0.75em;
		text-align: left;
	}

	table thead {
		border-bottom: solid 2px rgba(255, 255, 255, 0.15);
	}

	table tfoot {
		border-top: solid 2px rgba(255, 255, 255, 0.15);
	}

	table.alt {
		border-collapse: separate;
	}

	table.alt tbody tr td {
		border: solid 1px rgba(255, 255, 255, 0.15);
		border-left-width: 0;
		border-top-width: 0;
	}

		table.alt tbody tr td:first-child {
			border-left-width: 1px;
		}

	table.alt tbody tr:first-child td {
		border-top-width: 1px;
	}

	table.alt thead {
		border-bottom: 0;
	}

	table.alt tfoot {
		border-top: 0;
	}

/* Wrapper */

	.wrapper {
		position: relative;
	}

	.wrapper > .inner {
		padding: 0em 5em 3em 5em ;
		max-width: 100%;
		width: 75em;
	}

	@media screen and (max-width: 1680px) {

		.wrapper > .inner {
			padding: 0em 4em 2em 4em ;
			width:100%;
		}

	}

	@media screen and (max-width: 1280px) {

		.wrapper > .inner {
			width: 100%;
		}

	}

	@media screen and (max-width: 736px) {

		.wrapper > .inner {
			padding: 3em 2em 1em 2em ;
		}

	}

	.wrapper.alt {
		background-color: #000;
	}

	.wrapper.style1 {
		background-color: #000;
		background-image:'url(../../img/footer_background.jpg)';
		background-size:cover;
		xbox-shadow:#000;
		box-shadow:inset 0 20px 20px -20px;
	}

	.wrapper.style1-alt {
		background-color: #000;
	}

	.wrapper.style2 {
		background-color: #FFF;
		xbox-shadow:#000;
		xbox-shadow:inset 0px 20px 20px -20px;
	}

	.wrapper.style2-alt {
		background-color: #FFF;
	}

	.wrapper.style3 {
		background-color: #ffffff;
		background-image:'url(../../img/faq_bg.jpg)';
		background-size:cover;
	}

	.wrapper.style3-alt {
		background-color: #FFF;
	}

	.wrapper.fullscreen {
		display: -moz-flex;
		display: -ms-flex;
		display: flex;
		-moz-flex-direction: column;
		flex-direction: column;
		-moz-justify-content: center;
		-ms-justify-content: center;
		justify-content: center;
		min-height: 100vh;
	}

		body.is-ie .wrapper.fullscreen {
			height: 100vh;
		}

		@media screen and (max-width: 1280px) {

			.wrapper.fullscreen {
				min-height: calc(100vh - 2.5em);
			}

				body.is-ie .wrapper.fullscreen {
					height: calc(100vh - 2.5em);
				}

		}

		@media screen and (max-width: 736px) {

			.wrapper.fullscreen {
				padding: 2em 0;
				min-height: 0;
			}

				body.is-ie .wrapper.fullscreen {
					height: auto;
				}

		}

	.wrapper.fade-up > .inner {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		transition: opacity 1s ease, -webkit-transform 1s ease;
		transition: opacity 1s ease, transform 1s ease;
		transition: opacity 1s ease, transform 1s ease, -webkit-transform 1s ease;
		opacity: 1.0;
	}

	.wrapper.fade-up.inactive > .inner, body.is-loading .wrapper.fade-up > .inner {
		opacity: 0;
		-webkit-transform: translateY(1em);
		transform: translateY(1em);
	}

	.wrapper.fade-down > .inner {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		transition: opacity 1s ease, -webkit-transform 1s ease;
		transition: opacity 1s ease, transform 1s ease;
		transition: opacity 1s ease, transform 1s ease, -webkit-transform 1s ease;
		opacity: 1.0;
	}

	.wrapper.fade-down.inactive > .inner, body.is-loading .wrapper.fade-down > .inner {
		opacity: 0;
		-webkit-transform: translateY(-1em);
		transform: translateY(-1em);
	}

	.wrapper.fade > .inner {
		transition: opacity 1s ease;
		opacity: 1.0;
	}

	.wrapper.fade.inactive > .inner, body.is-loading .wrapper.fade > .inner {
		opacity: 0;
	}



/* Intro */

	#intro {
		zbackground-image:none;
		background-attachment: fixed;
		background-image:'url(../../img/home_background4.jpg)';
		background-position: top right;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		box-shadow:inset -20px -25px 15px -30px #000;
	}

	#intro p {
		font-family:inherit;
		font-size: 1em;
		font-weight: bold;
		color:#444;
	}

	@media screen and (max-width: 980px) {
		#intro p br {
			display: none;
			background-size: 100% 100%;
		}

		.condicoGifImage {
			width: 400px
		}
	}

	@media screen and (max-width: 736px) {
		#intro p {
			font-size: 1em;
			background-size: 100% 100%;
		}

		.condicoGifImage {
			width: 600px
		}
	}

	@media screen and (max-width: 1280px) {

		#intro {
			background-attachment: scroll;
			background-size: 100% 100%;
		}

		.condicoGifImage {
			width: 300px
		}
	}
.ButtonContainer {
    width: 50%;
    margin: auto;
}

  .videoBox {
    width: 100%;
    height: 75%;
    padding-bottom: 56.25%;
    position: relative;
  }
  .player {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
.contactUsJumbotron {
    margin: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    text-align: left;
  }

  .contactUsJumbotron .infoDivider {
    height:20vh;
  }

  .contactUsJumbotron .transactionDivider {
    height:15vh;
  }

  .contactUsJumbotron .messageDivider {
    height:65vh;
  }

  .contactInfo {
    display: flex;
    flex-direction: column;
  }

  .contactUsCustomText {
      font-size: 16px;
      font-family: "Roboto, helvetica";
      color: var(--darkGrey_theme);
  }

  .address, .messageRow {
    display: flex;
    flex-direction: row;
  }

  .messageRow {
    justify-content: space-between;
  }

  .ButtonContainer {
    width: 50%;
    margin: auto;
  }
.Summary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "Helvetica";
}

.ButtonContainer {
    width: 50%;
    margin: auto;
}
.subHeading {
    color: black;
    font-size: 14px;
}

.text {
    color: black;
    font-size: 12px;
}
@media all and (min-width: 480px) {
  
    .ForgotPassword {
      padding: 60px 0;
    }

    .ForgotPassword form {
      margin: 0 auto;
      max-width: 320px;
    }

    .ForgotPasswordLeadPages form {
      margin: 0 auto;
      max-width: 320px;
    }
    
  }

  .ForgotPasswordLeadPages {
    padding: 60px 0;
    background: linear-gradient(#1B1836,#1B1836);
    height: 2000px;
  }
  .homeJumbotron {
    margin-top: -20px;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: -10px;
    text-align: center;
    background: linear-gradient(#8ACF2E,#808080,#8ACF2E);
  }

  .homeJumbotron .statistic {
    height:50vh;
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: center;
  }
  
  .homeJumbotron h1, p {
    font-style: italic;
    font-weight: 600;
    color: var(--grey_theme);
    align-self: center;
  }

  .homeJumbotron p {
    font-size: 2em;
  }

  a:hover {
    cursor:pointer;
   }
@media all and (min-width: 480px) {
    .Login {
      display: flex;
      flex-direction: column;
      padding: 60px 0;
      background: linear-gradient(#808080,#fff);
    }
  
    .Login form {
      margin: 0 auto;
      max-width: 320px;
    }

    .LoginLeadPages form {
      margin: 0 auto;
      max-width: 320px;
    }
}

.LoginLeadPages {
  display: flex;
  flex-direction: column;
  padding: 60px 0;
  background: linear-gradient(#1B1836,#1B1836);
}

.ForgotLink {
  align-self: center;
}
  .emptyCell {
    text-align: center;
  }

  .emptyCell h3 {
    padding-top: 25px;
    padding-left: 10px;
    color: #8ACF2E;
  }

  .More .cells p {
    color: #666;
  }

  .cell {
    display: flex;
    justify-content: space-between;
  }

  .mainContent h3 {
    padding-left: 10px;
    display: inline;
  }

  .mainContent img {
    margin-bottom: 5px;
  }

  .disclosureIndicatorBox {
    align-self: center;
  }
 
  .Create {
    display: flex;
    flex-direction: row;
    justify-content: left;
  }

  .Create h4 {
    align-self: center;
    padding-left: 8px;
    padding-top: 10px;
  }

  .emptyCell {
    text-align: center;
  }

  .emptyCell h3, p {
    padding-top: 25px;
    padding-left: 10px;
    color: #8ACF2E;
  }

  .MyCondicos .condicos h4 {
    font-weight: 600;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .MyCondicos .condicos p {
    color: #666;
  }

  .condicoCell {
    display: flex;
    justify-content: space-between;
    font-family: "Helvetica";
  }

  .mainContent span {
    padding-left: 10px;
    padding-top: 5px;
  }

  .mainContent h3 {
    padding-top: 5px;
    display: inline;
    font-family: "Helvetica";
  }

  .disclosureIndicatorBox {
    align-self: center;
  }

  hr {
    margin-top: 1px;
    border: 0;
    clear:both;
    width: 100%;                
    background-color: var(--grey_theme);
    height: 1px;
}

.mycondicoCustomText {
  font-size: 11px;
  font-family: "Helvetica";
  color: var(--grey_theme);
}

.NotFound {
    padding-top: 100px;
    text-align: center;
  }
.ButtonContainer {
    width: 50%;
    margin: auto;
}
.greenText {
    color: var(--green_theme);
}

.blackText {
    color: black;
}

.cardCell {
    display: flex;
    justify-content: space-between;
    background-color: #F5F5F5;
  }

  .imageListContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }

  .imageListContainer img {
    align-self: center;
  }
.Summary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "Helvetica";
}

.ButtonContainer {
    width: 50%;
    margin: auto;
}
p {
    color: var(--grey_theme);
    font-size: 1em;
    font-family: "Roboto, helvetica";
    font-style: normal;
    padding-left: 10px;
}

.ButtonContainer {
    width: 50%;
    margin: auto;
}
.ButtonContainer {
    width: 50%;
    margin: auto;
}
.Summary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "Helvetica";
}

.ButtonContainer {
    width: 50%;
    margin: auto;
}

.rescindButton {
    background-color: red;
    color: #ffffff;
}
br {
    line-height: 200%;
}

.imageBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.stateBox {
    display: flex;
    justify-content: center;
    align-items: center;
}

.summaryBox {
    display: flex;
    flex-direction: row;
}
@media all and (min-width: 480px) {
    .Signup {
      padding: 60px 0;
      background: linear-gradient(#808080,#fff);
    }
  
    .Signup form {
      margin: 0 auto;
      max-width: 320px;
    }

    .SignUpLeadPages form {
      margin: 0 auto;
      max-width: 320px;
    }

    .alert {
      margin-top: -60px;
    }

  }

  .SignUpLeadPages {
    padding: 60px 0;
    background: linear-gradient(#1B1836,#1B1836);
    height: 2000px;
  }
.ButtonContainer {
  width: 50%;
  margin: auto;
}
.Outer {
    margin-top: -80px;
    background: linear-gradient(#808080,#fff);
    /*background: linear-gradient(#fff,var(--green_theme),#fff);*/
}

.LowerContainer {
    width: 50%;
    margin: auto;
    padding-bottom: 10px;
}

.LowerContainer.button {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    transition: border-color 0.2s ease;
    background-color:#8ACF2E;
    xborder: solid 1px ;
    xborder-color: #7BD728; ;
    border-radius: 3em;
    color: #FFF; ;
    cursor: pointer;
    display: inline-block;
    font-size: 0.9em;
    font-weight: bold;
    font-family: "AvenirNext";
    xheight: calc(4.75em + 2px);
    xletter-spacing: 0.1em;
    line-height: 2em;
    outline: 0;
    padding: .5em 2em;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
}

.Main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title {
    padding-left: 10px;
    padding-top: 100px;
    padding-bottom: 100px;
}

.title h2.titleHeader, p.subHeader {
    padding-top: 5px;
    padding-right: 5px;
    text-align: center;
    color: #fff;
    font-family: "Roboto, helvetica";
}

.imageContent {
    align-self: center;
    margin-bottom: 100px;
}

.lastImageContent {
    align-self: center;
    margin-bottom: 50px;
}

.image {
    align-self: center;
}

:root {
  --grey_theme: #C7C7C7;
  --darkGrey_theme: #333;
  --blue_theme: #00b;
  --green_theme: #97D352;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto, helvetica";
  font-size: 16px;
  color: #333;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto, helvetica";
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
