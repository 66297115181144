  .homeJumbotron {
    margin-top: -20px;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: -10px;
    text-align: center;
    background: linear-gradient(#8ACF2E,#808080,#8ACF2E);
  }

  .homeJumbotron .statistic {
    height:50vh;
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: center;
  }
  
  .homeJumbotron h1, p {
    font-style: italic;
    font-weight: 600;
    color: var(--grey_theme);
    align-self: center;
  }

  .homeJumbotron p {
    font-size: 2em;
  }

  a:hover {
    cursor:pointer;
   }