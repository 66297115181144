.App {
  width: 100vw;
}

.navbar {
  margin-bottom: 0;
  background-color: #a9a9a9;
  min-height: 80px;
}

.navMenuIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.navMenuIcon img {
  align-self: center;
  padding-right: 3px;
}

.navMenuIcon p {
  align-self: center;
  font-weight: normal;
  padding: 0;
  margin: 0;
  font-family: "Roboto, helvetica";
  font-size: 16px;
}

.navbar-default .navbar-nav> .active> a, .navbar-default .navbar-nav > .active> a:focus, .navbar-default .navbar-nav> .active > a:hover {
  color: #669900;
  background-color: transparent;
}

.navbar-default .navbar-nav > li > a {
  color:black;
  border-width: 0px;
}

.navbar-default .navbar-brand {
  color:black;
  border-width: 0px;
  height: 80px;
  line-height: 80px;
}

.navbar-toggle {
  /* (80px - button height 34px) / 2 = 23px */
  margin-top: 23px;
  padding: 9px 10px !important;
}

.navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:hover {
  color:#777;
  border-width: 0px;
}

.navbar-default .navbar-brand:active, .navbar-default .navbar-brand:focus, .navbar-default .navbar-brand:hover {
  color:#777;
  border-width: 0px;
}

button:focus {outline:0;}

