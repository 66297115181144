.Summary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "Helvetica";
}

.ButtonContainer {
    width: 50%;
    margin: auto;
}

.rescindButton {
    background-color: red;
    color: #ffffff;
}