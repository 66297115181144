
:root {
  --grey_theme: #C7C7C7;
  --darkGrey_theme: #333;
  --blue_theme: #00b;
  --green_theme: #97D352;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto, helvetica";
  font-size: 16px;
  color: #333;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto, helvetica";
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}