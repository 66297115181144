  .Create {
    display: flex;
    flex-direction: row;
    justify-content: left;
  }

  .Create h4 {
    align-self: center;
    padding-left: 8px;
    padding-top: 10px;
  }

  .emptyCell {
    text-align: center;
  }

  .emptyCell h3, p {
    padding-top: 25px;
    padding-left: 10px;
    color: #8ACF2E;
  }

  .MyCondicos .condicos h4 {
    font-weight: 600;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .MyCondicos .condicos p {
    color: #666;
  }

  .condicoCell {
    display: flex;
    justify-content: space-between;
    font-family: "Helvetica";
  }

  .mainContent span {
    padding-left: 10px;
    padding-top: 5px;
  }

  .mainContent h3 {
    padding-top: 5px;
    display: inline;
    font-family: "Helvetica";
  }

  .disclosureIndicatorBox {
    align-self: center;
  }

  hr {
    margin-top: 1px;
    border: 0;
    clear:both;
    width: 100%;                
    background-color: var(--grey_theme);
    height: 1px;
}

.mycondicoCustomText {
  font-size: 11px;
  font-family: "Helvetica";
  color: var(--grey_theme);
}
