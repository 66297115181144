.Outer {
    margin-top: -80px;
    background: linear-gradient(#808080,#fff);
    /*background: linear-gradient(#fff,var(--green_theme),#fff);*/
}

.LowerContainer {
    width: 50%;
    margin: auto;
    padding-bottom: 10px;
}

.LowerContainer.button {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -moz-transition: border-color 0.2s ease;
    -webkit-transition: border-color 0.2s ease;
    -ms-transition: border-color 0.2s ease;
    transition: border-color 0.2s ease;
    background-color:#8ACF2E;
    xborder: solid 1px ;
    xborder-color: #7BD728; ;
    border-radius: 3em;
    color: #FFF; ;
    cursor: pointer;
    display: inline-block;
    font-size: 0.9em;
    font-weight: bold;
    font-family: "AvenirNext";
    xheight: calc(4.75em + 2px);
    xletter-spacing: 0.1em;
    line-height: 2em;
    outline: 0;
    padding: .5em 2em;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
}

.Main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title {
    padding-left: 10px;
    padding-top: 100px;
    padding-bottom: 100px;
}

.title h2.titleHeader, p.subHeader {
    padding-top: 5px;
    padding-right: 5px;
    text-align: center;
    color: #fff;
    font-family: "Roboto, helvetica";
}

.imageContent {
    align-self: center;
    margin-bottom: 100px;
}

.lastImageContent {
    align-self: center;
    margin-bottom: 50px;
}

.image {
    align-self: center;
}