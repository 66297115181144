@media all and (min-width: 480px) {
    .Login {
      display: flex;
      flex-direction: column;
      padding: 60px 0;
      background: linear-gradient(#808080,#fff);
    }
  
    .Login form {
      margin: 0 auto;
      max-width: 320px;
    }

    .LoginLeadPages form {
      margin: 0 auto;
      max-width: 320px;
    }
}

.LoginLeadPages {
  display: flex;
  flex-direction: column;
  padding: 60px 0;
  background: linear-gradient(#1B1836,#1B1836);
}

.ForgotLink {
  align-self: center;
}