.btn-default {
    box-shadow: none !important;
    outline: none !important;
}

.btn-default:hover, .btn-default:focus, .btn-default:active {
    box-shadow: none !important;
    outline: none !important;
}

.btn-default.active {
    box-shadow: none !important;
    outline: none !important;
}