  .emptyCell {
    text-align: center;
  }

  .emptyCell h3 {
    padding-top: 25px;
    padding-left: 10px;
    color: #8ACF2E;
  }

  .More .cells p {
    color: #666;
  }

  .cell {
    display: flex;
    justify-content: space-between;
  }

  .mainContent h3 {
    padding-left: 10px;
    display: inline;
  }

  .mainContent img {
    margin-bottom: 5px;
  }

  .disclosureIndicatorBox {
    align-self: center;
  }
 