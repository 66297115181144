p {
    color: var(--grey_theme);
    font-size: 1em;
    font-family: "Roboto, helvetica";
    font-style: normal;
    padding-left: 10px;
}

.ButtonContainer {
    width: 50%;
    margin: auto;
}